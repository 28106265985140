import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTInviteFriendsModal = _resolveComponent("KTInviteFriendsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Invite Friends Modal Example",
      description: "Click on the below buttons\n  to launch <br />\n  a friend invitation example.",
      image: _ctx.getIllustrationsPath('1.png'),
      "button-text": "Invite Friends",
      "modal-id": "kt_modal_invite_friends"
    }, null, 8, ["image"]),
    _createVNode(_component_KTInviteFriendsModal)
  ], 64))
}