import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_invite_friends",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header pb-0 border-0 justify-content-end" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15" }
const _hoisted_8 = { class: "mb-10" }
const _hoisted_9 = { class: "mh-300px scroll-y me-n7 pe-7" }
const _hoisted_10 = { class: "d-flex align-items-center" }
const _hoisted_11 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "ms-5" }
const _hoisted_14 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
}
const _hoisted_15 = { class: "fw-bold text-gray-400" }
const _hoisted_16 = { class: "ms-2 w-100px" }
const _hoisted_17 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"text-center mb-13\"><h1 class=\"mb-3\">Invite a Friend</h1><div class=\"text-gray-400 fw-bold fs-5\"> If you need more info, please check out <a href=\"#\" class=\"link-primary fw-bolder\">FAQ Page</a>. </div></div><div class=\"btn btn-light-primary fw-bolder w-100 mb-8\"><img alt=\"Logo\" src=\"media/svg/brand-logos/google-icon.svg\" class=\"h-20px me-3\"> Invite Gmail Contacts </div><div class=\"separator d-flex flex-center mb-8\"><span class=\"text-uppercase bg-white fs-7 fw-bold text-gray-400 px-3\">or</span></div><textarea class=\"form-control form-control-solid mb-8\" rows=\"3\" placeholder=\"Type or paste emails here\">          </textarea>", 4)),
          _createElementVNode("div", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fs-6 fw-bold mb-2" }, "Your Invitations", -1)),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed"
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      (user.avatar)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            alt: "Pic",
                            src: user.avatar
                          }, null, 8, _hoisted_12))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass([`bg-light-${user.state} text-${user.state}`, "symbol-label fw-bold"])
                          }, _toDisplayString(user.name.charAt(0)), 3))
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("a", _hoisted_14, _toDisplayString(user.name), 1),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(user.email), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": ($event: any) => ((user.access) = $event),
                      class: "form-select form-select-solid form-select-sm",
                      "data-control": "select2",
                      "data-hide-search": "true"
                    }, _cache[0] || (_cache[0] = [
                      _createElementVNode("option", { value: "1" }, "Guest", -1),
                      _createElementVNode("option", { value: "2" }, "Owner", -1),
                      _createElementVNode("option", { value: "3" }, "Can Edit", -1)
                    ]), 8, _hoisted_17), [
                      [_vModelSelect, user.access]
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"d-flex flex-stack\"><div class=\"me-5 fw-bold\"><label class=\"fs-6\">Adding Users by Team Members</label><div class=\"fs-7 text-gray-400\"> If you need more info, please check budget planning </div></div><label class=\"form-check form-switch form-check-custom form-check-solid\"><input class=\"form-check-input\" type=\"checkbox\" value=\"1\" checked=\"checked\"><span class=\"form-check-label fw-bold text-gray-400\"> Allowed </span></label></div>", 1))
        ])
      ])
    ])
  ]))
}